var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.reservationInfo)?_c('section',{attrs:{"id":"check"}},[_c('div',{staticClass:"join_info"},[_c('div',{staticClass:"reserve_info",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.goReservationStatusPage.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.reservationDateHeaderLabel)+" "+_vm._s(_vm.reservationDateHeaderSubLabel)+" "+_vm._s(_vm.reservationCourseHeaderLabel)+" "),_c('p',{on:{"click":_vm.goReservationStatusPage}},[_c('strong',[_vm._v(_vm._s(_vm.ccName))])]),_c('div',{staticClass:"refresh",on:{"click":_vm.fetch}})])]),_c('div',{staticClass:"main_btn_wrap"},[_c('p',{staticClass:"stitle"},[_vm._v(_vm._s(_vm.$t("checkInMessage.finishedCheckIn")))]),_c('div',{staticClass:"main_locker"},[_c('div',{staticClass:"fl"},[_vm._v(" "+_vm._s(_vm.$t("main.issuanceLockerNo"))+" "),_c('p',[_vm._v(" "+_vm._s(_vm.$t("checkInMessage.finishedCheckInSuffix", [ _vm.signedInCmpnMemberName ]))+" ")])]),_c('div',{staticClass:"fr",on:{"click":_vm.onClickLockerNo}},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("main.lockerNoLabel"))+" ")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.filteredLockerNo)+" "),(_vm.filteredLockerNo !== _vm.$t('locker.unpublished'))?_c('span',[_vm._v(" ("+_vm._s(_vm.signedInCmpnSexName)+") ")]):_vm._e()])])]),(_vm.isVisitor)?_c('a',{staticClass:"btn_point btn_checkout",on:{"click":_vm.goPayPage}},[_vm._v(" "+_vm._s(_vm.$t("main.paymentOfUsageFee"))+" ")]):_vm._e()]),_c('div',{staticClass:"play_list"},[_c('a',{staticClass:"btn_tip"},[_vm._v(_vm._s(_vm.$t("main.myPlayCompanion")))]),_c('ul',{staticClass:"people_list"},[_vm._l((_vm.viewCmpnList),function(member){return _c('li',{key:("member" + (member.sno)),on:{"click":function($event){return _vm.onClickModifyPartner(member)}}},[_c('span',{class:[
            'ico',
            _vm.reservationSexClass(member.sexCode),
            !_vm.isEqualsFirstCompanion && member.reprsntFlag && 'crown' ]}),_vm._v(" "+_vm._s(member.cmpnName || member.visitName)+" "),(
            _vm.isBooker &&
            member.resveId &&
            !member.reprsntFlag &&
            !member.isCheckedIn
          )?_c('a',{staticClass:"btn_people",attrs:{"data-animation":"fade"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onClickDeletePartner(member.sno)}}}):_vm._e(),(!_vm.isVisitor && member.isCheckedIn)?_c('a',{staticClass:"ico_checkin"}):_vm._e()])}),_vm._l((_vm.reservationCmpnMoreList),function(more){return _c('li',{key:("more" + (more.sno)),on:{"click":_vm.onClickAddPartner}},[_vm._m(0,true)])})],2)]),(_vm.isLockerOkPopupOpened)?_c('locker-ok-popup',{attrs:{"lockerNo":_vm.filteredLockerNo,"sexName":_vm.signedInCmpnSexName,"isNotNewLocker":true},on:{"submit":_vm.onLockerOkPopupSubmit}}):_vm._e(),(_vm.isPartnerOkPopupOpened)?_c('partner-ok-popup',{on:{"submit":_vm.onPartnerOkPopupSubmit}}):_vm._e(),(_vm.isPartnerRegisterPopupOpened)?_c('partner-register-popup',{ref:"partnerRegisterPopup",on:{"close":_vm.onPartnerRegisterPopupClose,"submit":_vm.onPartnerRegisterPopupSubmit}}):_vm._e(),(_vm.isPartnerModifyPopupOpened)?_c('partner-modify-popup',{ref:"partnerModifyPopup",on:{"close":_vm.onPartnerModifyPopupClose,"submit":_vm.onPartnerModifyPopupSubmit}}):_vm._e(),(_vm.isPartnerDeletePopupOpened)?_c('partner-delete-popup',{ref:"partnerDeletePopup",on:{"close":_vm.onPartnerDeletePopupClose,"submit":_vm.onPartnerDeletePopupSubmit}}):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"data-animation":"fade"}},[_c('span',{staticClass:"ico more"})])}]

export { render, staticRenderFns }