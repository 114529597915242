<template>
  <section v-if="reservationInfo" id="check">
    <div class="join_info">
      <div class="reserve_info" @click.self="goReservationStatusPage">
        {{ reservationDateHeaderLabel }}
        {{ reservationDateHeaderSubLabel }}
        {{ reservationCourseHeaderLabel }}
        <p @click="goReservationStatusPage">
          <strong>{{ ccName }}</strong>
        </p>
        <!-- {{ $t("common.persons", [visitCount]) }}-->
        <div class="refresh" @click="fetch" />
      </div>
    </div>
    <div class="main_btn_wrap">
      <p class="stitle">{{ $t("checkInMessage.finishedCheckIn") }}</p>
      <div class="main_locker">
        <div class="fl">
          {{ $t("main.issuanceLockerNo") }}
          <p>
            {{
              $t("checkInMessage.finishedCheckInSuffix", [
                signedInCmpnMemberName,
              ])
            }}
          </p>
        </div>
        <div class="fr" @click="onClickLockerNo">
          <div class="title">
            {{ $t("main.lockerNoLabel") }}
          </div>
          <div class="content">
            {{ filteredLockerNo }}
            <span v-if="filteredLockerNo !== $t('locker.unpublished')">
              ({{ signedInCmpnSexName }})
            </span>
          </div>
        </div>
      </div>
      <a v-if="isVisitor" class="btn_point btn_checkout" @click="goPayPage">
        {{ $t("main.paymentOfUsageFee") }}
      </a>
    </div>
    <div class="play_list">
      <a class="btn_tip">{{ $t("main.myPlayCompanion") }}</a>
      <ul class="people_list">
        <li
          v-for="member in viewCmpnList"
          :key="`member${member.sno}`"
          @click="onClickModifyPartner(member)"
        >
          <span
            :class="[
              'ico',
              reservationSexClass(member.sexCode),
              !isEqualsFirstCompanion && member.reprsntFlag && 'crown',
            ]"
          />
          {{ member.cmpnName || member.visitName }}
          <a
            v-if="
              isBooker &&
              member.resveId &&
              !member.reprsntFlag &&
              !member.isCheckedIn
            "
            class="btn_people"
            data-animation="fade"
            @click.self="onClickDeletePartner(member.sno)"
          />
          <a v-if="!isVisitor && member.isCheckedIn" class="ico_checkin" />
        </li>
        <li
          v-for="more in reservationCmpnMoreList"
          :key="`more${more.sno}`"
          @click="onClickAddPartner"
        >
          <a data-animation="fade">
            <span class="ico more" />
          </a>
        </li>
      </ul>
    </div>
    <locker-ok-popup
      v-if="isLockerOkPopupOpened"
      :lockerNo="filteredLockerNo"
      :sexName="signedInCmpnSexName"
      :isNotNewLocker="true"
      @submit="onLockerOkPopupSubmit"
    />
    <partner-ok-popup
      v-if="isPartnerOkPopupOpened"
      @submit="onPartnerOkPopupSubmit"
    />
    <partner-register-popup
      v-if="isPartnerRegisterPopupOpened"
      ref="partnerRegisterPopup"
      @close="onPartnerRegisterPopupClose"
      @submit="onPartnerRegisterPopupSubmit"
    />
    <partner-modify-popup
      v-if="isPartnerModifyPopupOpened"
      ref="partnerModifyPopup"
      @close="onPartnerModifyPopupClose"
      @submit="onPartnerModifyPopupSubmit"
    />
    <partner-delete-popup
      v-if="isPartnerDeletePopupOpened"
      ref="partnerDeletePopup"
      @close="onPartnerDeletePopupClose"
      @submit="onPartnerDeletePopupSubmit"
    />
  </section>
</template>

<script>
import LockerOkPopup from "@/components/reservations/popup/LockerOkPopup";
import checkInMixin from "@/mixins/checkInMixin";
import { mapActions, mapGetters } from "vuex";
import { getEncAndRedirectAndPhoneNumber } from "@/utils/session/SessionUtil";

export default {
  name: "CheckInStatus",
  components: { LockerOkPopup },
  mixins: [checkInMixin],
  watch: {
    async isCheckedIn(isCheckedIn) {
      if (!isCheckedIn) await this.goReservationStatusPage();
    },
  },
  computed: {
    ...mapGetters(["ccName"]),
    filteredLockerNo() {
      return this.signedInCmpnMemberLockerNo &&
        this.signedInCmpnMemberLockerNo !== "000" &&
        this.signedInCmpnMemberLockerNo !== "999"
        ? this.signedInCmpnMemberLockerNo
        : this.$t("locker.unpublished");
    },
  },
  methods: {
    ...mapActions(["clearUserSession"]),
    async onClickLockerNo() {
      await this.$nextTick();
      this.isLockerOkPopupOpened = true;
    },
    async goPayPage() {
      await this.$router.push(`/check-out/pay`);
    },
    async goReservationStatusPage() {
      if (this.isVisitor) return;
      await this.$router.push(`/reservations/${this.reservationId}`);
    },
  },
  /* 뒤로가기 버튼 클릭시 팝업 열린 상태면 팝업만 닫히도록 처리 */
  async beforeRouteLeave(to, from, next) {
    if (this.isPopupOpened) {
      this.closeAllPopup();
      const UUID = Math.round(Math.random() * 999999).toString();
      await this.$router.push(`${this.$route.path}?uuid=${UUID}`);
    } else {
      if (
        this.isVisitor &&
        to.name === "ReservationStatus" &&
        from.name === "CheckInStatus"
      ) {
        // TODO : S_VISITOR 면서 체크인 현황에서 뒤로가기 시 토큰 비우고 로그인 페이지로 이동 처리
        const { enc, redirect } = getEncAndRedirectAndPhoneNumber();
        await this.clearUserSession();
        await this.$router.push(`/sign-in?enc=${enc}&redirect=${redirect}`);
      }
    }
    next();
  },
};
</script>
