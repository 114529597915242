<template>
  <div id="okModal" class="modal-wrap">
    <div class="popup_ok_wrap">
      <p class="title" v-if="!isNotNewLocker">
        {{ $t("checkInMessage.finishedCheckIn") }}
      </p>
      <div class="locker_ticket checkin_ok">
        <div class="ticket_info">
          <span>{{ $t("main.lockerNoLabel") }}</span>
          <div class="locker_no">
            {{ filteredLockerNo }}
            <span v-if="filteredLockerNo !== $t('locker.unpublished')">
              ({{ sexName }})
            </span>
          </div>
        </div>
        <p>
          {{ $t("lockerOkMessage._1") }}
          <br />
          {{ $t("lockerOkMessage._2") }}
          <br />
          {{ $t("lockerOkMessage._3") }}
        </p>
      </div>
    </div>
    <div class="modal_btn">
      <a class="btn_close close-reveal-modal" @click="onClickSubmit">
        {{ $t("common.submit") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LockerOkPopup",
  props: {
    lockerNo: {
      type: String,
      default: null,
    },
    sexName: {
      type: String,
      default: null,
    },
    isNotNewLocker: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredLockerNo() {
      return this.lockerNo && this.lockerNo !== "000" && this.lockerNo !== "999"
        ? this.lockerNo
        : this.$t("locker.unpublished");
    },
  },
  methods: {
    onClickSubmit() {
      this.$emit("submit");
    },
  },
};
</script>
